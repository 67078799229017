import {graphql, Link} from "gatsby";
import React from "react";
import showdown from 'showdown'

const converter = new showdown.Converter()
const HomeHeaderComponent = ({image, title, subtitle, description, alert}) => {
    console.log(alert);
    return (
        <>
            <header className={'mb-5'}>
                <div className="page-header min-vh-75">
                    <div className="oblique position-absolute top-0 h-100 d-md-block d-none">
                        <div
                            className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                            style={{backgroundImage: `url(${image})` }}></div>
                    </div>
                    <div className="container pt-6 pt-md-8">
                        <div className="row">
                            <div
                                className="col-lg-6 col-md-7 d-flex justify-content-center text-md-start text-center flex-column mt-sm-0 mt-7">
                                <h1 className="display-1 text-gradient text-primary">{title}</h1>
                                <h2 className="display-4 mb-4">{subtitle}</h2>
                                <p className="lead pe-md-5 me-md-5" dangerouslySetInnerHTML={{__html: converter.makeHtml(description)}}/>
                                <div className="buttons">
                                    <Link to={"/contact"} className="btn bg-gradient-primary mt-4">Demander un
                                        devis
                                    </Link>
                                    <Link to={"/qui-sommes-nous"} type="button" className="btn text-primary shadow-none mt-4">En savoir
                                        +
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {alert != null ? (
                <div className="col-12 mx-auto">
                    <div className="toast fade show p-2 mx-auto mb-4 w-75" role="alert" aria-live="assertive"
                         aria-atomic="true">
                        <div className="toast-body text-center">
                            <span className={'h3'}>{alert}</span>
                            {/*<hr className="horizontal dark"/>*/}
                        </div>
                    </div>
                </div>
            ) : <></>}
        </>

    )
}

export default HomeHeaderComponent;

export const query = graphql`
  fragment HeaderFields on MarkdownRemarkFrontmatter {
    header {
      title
      subtitle
      description
      image
      alert
    }
  }
`;
