import {graphql} from "gatsby";
import React from "react";
import ContactFormComponent from "./contactForm";
import showdown from "showdown";

const converter = new showdown.Converter();

const ContactHeaderComponent = ({image, title, subtitle, description, alert}) => {
    return (
        <>
            <header>
                <div className="page-header pt-0 pt-sm-6">
                    <div
                        className="position-absolute fixed-top ms-auto w-100 w-xl-50 h-100 z-index-0"
                        style={{backgroundImage: `url(${image})`}}/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 d-flex justify-content-center flex-column">
                                <div
                                    className="card d-flex blur justify-content-center p-2 shadow-lg my-sm-0 my-sm-6 mt-8 mb-5">
                                    <div className="text-center pt-2">
                                        <h1 className="display-4 text-gradient text-primary">{title}</h1>
                                    </div>

                                    <ContactFormComponent/>

                                    <p className="mb-0 mt-4 text-center" dangerouslySetInnerHTML={{__html: converter.makeHtml(description)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {alert != null ? (
                <div className="col-12 mx-auto mt-4">
                    <div className="toast fade show p-2 mx-auto w-75" role="alert" aria-live="assertive"
                         aria-atomic="true">
                        <div className="toast-body text-center">
                            <span className={'h3'}>{alert}</span>
                            {/*<hr className="horizontal dark"/>*/}
                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default ContactHeaderComponent;

export const query = graphql`
  fragment HeaderFields on MarkdownRemarkFrontmatter {
    header {
      title
      subtitle
      description
      image
      alert
    }
  }
`;
