import * as React from "react"

import LayoutComponent from "../components/layout";
import {graphql} from "gatsby";
import MetadataComponent from "../components/fields/metadata";
import ContactHeaderComponent from "../components/fields/contact/header";
import HomeHeaderComponent from "../components/fields/home/header";
import ContactFormComponent from "../components/fields/contact/contactForm";
import MissionsHeaderComponent from "../components/fields/missions/header";

const ContactPage = ({data}) => {
    const {frontmatter} = data.allMarkdownRemark.nodes[0];

    return (
        <>
            <MetadataComponent
                description={frontmatter.metadata.description}
                title={frontmatter.metadata.title}
                keywords={frontmatter.metadata.keywords}
                code={frontmatter.metadata.code}
            />

            <LayoutComponent activeCategory={"realisations"}>
                <ContactHeaderComponent
                    title={frontmatter.header.title}
                    subtitle={frontmatter.header.subtitle}
                    description={frontmatter.header.description}
                    image={frontmatter.header.image}
                    alert={frontmatter.header.alert}
                />

            </LayoutComponent>
        </>
    );
}

export default ContactPage;

export const pageQuery = graphql`
query {
  allMarkdownRemark(filter: {frontmatter: {metadata: {code: {eq: "contact"}}}}) {
    nodes {
      frontmatter {
        ...MetadataFields
        ...HeaderFields
      }
    }
  }
}
`
