import * as React from "react";

const ContactFormComponent = () => {
    return (
        <form id="contact-form" name="contact" method="POST" action="https://formspree.io/f/xeqyennl">
            <div className="card-body pb-2">
                <div className="row">
                    <div className="col-md-6">
                        <label>Nom et prénom</label>
                        <div className="input-group mb-4">
                            <input className="form-control" placeholder="Nom et prénom" aria-label="Nom et prénom"
                                   type="text" name={"name"} />
                        </div>
                    </div>
                    <div className="col-md-6 ps-md-2">
                        <label>Email</label>
                        <div className="input-group">
                            <input type="email" className="form-control" name={'email'} placeholder="contact@francedeboss.fr"/>
                        </div>
                    </div>
                </div>
                <div className="form-group mb-0 mt-md-0 mt-4">
                    <label>Comment pouvons nous vous aider ?</label>
                    <textarea name="message" className="form-control" id="message" rows="6"
                              placeholder="Votre message ici"></textarea>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <button type="submit" className="btn bg-gradient-primary mt-3 mb-0">Envoyer</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ContactFormComponent;
